import React from 'react'
import ReactDOM from 'react-dom'
import {createStore} from "redux";
import App from "./App";
import monitorReducer from "./reducers/MonitorReducer";


const store = createStore(monitorReducer)

const renderApp = () => {
    ReactDOM.render(<App store={store}/>, document.getElementById('calculator_root'))
}

renderApp();

store.subscribe(renderApp)
