import React from "react";

const apple_desktop = [
    {"k": "6016-3384-32-2", "v": "Apple Pro Display XDR"},
    {"k": "5120-2880-27-2", "v": "iMac Pro, iMac (27 inch)"},
    {"k": "4096-2304-21.5-2", "v": "iMac (21.5-inch)"}
]

const apple_laptop = [
    {"k": "3072-1920-16-1.714", "v": "MacBook Pro (16-inch)"},
    {"k": "2880-1800-15-1.714", "v": "MacBook Pro (15-inch)"},
    {"k": "2560-1600-13-1.778", "v": "MacBook Pro (13-inch), MacBook Air"}
]

const common_standards = [
    {"k": "1024-768-?-1", "v": "Extended Graphics Array, XGA"},
    {"k": "1280-720-?-1", "v": "HD Ready"},
    {"k": "1920-1080-?-1", "v": "Full HD"},
    {"k": "2560-1440-?-1", "v": "Quad HD, QHD"},
    {"k": "3840-2160-?-1", "v": "4K, Ultra HD"},
    {"k": "4096-2160-?-1", "v": "DCI 4K"},
    {"k": "7680-4320-?-1", "v": "8K"},

]

const apple_keys = [...apple_desktop, ...apple_laptop].map(x => x.k);
const common_keys = common_standards.map(x => x.k);

function buildKey(w, h, d, s) {
    return w + "-" + h + "-" + d + "-" + s;
}

const InputPixelsSelect = (props) => {
    const {store} = props;

    const state = store.getState()

    const apple_selection = buildKey(state.pixelWidth, state.pixelHeight, state.screenSize, store.getState().scale);
    const common_selection = buildKey(state.pixelWidth, state.pixelHeight, '?', store.getState().scale);
    console.log("apple_selection", apple_selection)
    console.log("common_selection", common_selection)

    let selection;
    if (apple_keys.includes(apple_selection)) {
        selection = apple_selection;
    } else if (common_keys.includes(common_selection)) {
        selection = common_selection;
    } else {
        selection = "custom";
    }

    console.log("rendering InputPixelsSelect", selection)

    return (
        <>
            <select
                className="form-select"
                id="pixels_select"
                name="pixels_select"
                onChange={e => store.dispatch({type: 'CHANGE_RESOLUTION_PRESET', data: e.target.value})}
                value={selection}
            >
                <option disabled>Apple Desktops</option>
                {apple_desktop.map((item) =>
                    <option key={item.k} value={item.k}>{item.v}</option>
                )}
                <option disabled>Apple Laptops</option>
                {apple_laptop.map((item) =>
                    <option key={item.k} value={item.k}>{item.v}</option>
                )}
                <option disabled>Some common standards</option>
                {common_standards.map((item) =>
                    <option key={item.k} value={item.k}>{item.v}</option>
                )}
                {(selection === "custom") && <option value="custom" disabled>Custom value</option>}
            </select>
        </>
    )
}

export default InputPixelsSelect
