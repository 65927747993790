import React from "react";
import InputPixelsHeight from "./InputPixelsHeight";
import InputPixelsSelect from "./InputPixelsSelect";
import InputPixelsWidth from "./InputPixelsWidth";
import InputScreenSize from "./InputScreenSize";


const InputScale = ({store}) => (
    <input type="number"
           className="form-control"
           id="scale_input"
           name="scale_input"
           value={store.getState().scale}
           onInput={e => store.dispatch({type: 'CHANGE_SCALE', data: e.target.value})}
    />
)

const InputLogicalWidth = ({store}) => (
    <input type="number"
           className="form-control"
           id="logical_width_input"
           name="logical_width_input"
           value={store.getState().logicalWidth}
           onInput={e => store.dispatch({type: 'CHANGE_LOGICAL_WIDTH', data: e.target.value})}
    />
)

const InputLogicalHeight = ({store}) => (
    <input type="number"
           className="form-control"
           id="logical_height_input"
           name="logical_height_input"
           value={store.getState().logicalHeight}
           onInput={e => store.dispatch({type: 'CHANGE_LOGICAL_HEIGHT', data: e.target.value})}
    />
)


const InputMonitorDetails = (props) => {
    const {store} = props;

    return (
        <form>
            <h3 className="mb-1">Physical pixels</h3>
            <div className="mb-0">
                <label htmlFor="screen_size_input" className="col-form-label">Existing display or standards</label>
                <InputPixelsSelect store={store}/>
            </div>
            <div className="row mb-3">
                <div className="col-md-4">
                    <label htmlFor="screen_size_input" className="col-form-label">Diagonal size in inches</label>
                    <InputScreenSize store={store}/>
                </div>
                <div className="col-md-4">
                    <label htmlFor="pixels_width_input" className="col-form-label">Width in pixels</label>
                    <InputPixelsWidth store={store}/>
                </div>
                <div className="col-md-4">
                    <label htmlFor="pixels_height_input" className="col-form-label">Height in pixels</label>
                    <InputPixelsHeight store={store}/>
                </div>
            </div>

            <h3 className="mb-1">Logical pixels</h3>
            <div className="row mb-3">
                <div className="col-md-4">
                    <label htmlFor="scale_input" className="col-form-label">Scale</label>
                    <InputScale store={store}/>
                </div>
                <div className="col-md-4">
                    <label htmlFor="logical_width_input" className="col-form-label">Logical width</label>
                    <InputLogicalWidth store={store}/>
                </div>
                <div className="col-md-4">
                    <label htmlFor="logical_height_input" className="col-form-label">Logical height</label>
                    <InputLogicalHeight store={store}/>
                </div>
            </div>
        </form>
    )
}

export default InputMonitorDetails
