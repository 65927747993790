

function createMonitorState(pixelWidth, pixelHeight, screenSize, scale) {
    return {
        pixelWidth: pixelWidth,
        pixelHeight: pixelHeight,
        screenSize: screenSize,
        scale: scale,
        logicalWidth: Math.round(pixelWidth / scale),
        logicalHeight: Math.round(pixelHeight / scale),
    }
}

const initialState = createMonitorState(1024, 768, 13, 1)

const monitorReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'CHANGE_PIXEL_WIDTH':
            return createMonitorState(action.data, state.pixelHeight, state.screenSize, state.scale);
        case 'CHANGE_PIXEL_HEIGHT':
            return createMonitorState(state.pixelWidth, action.data, state.screenSize, state.scale);
        case 'CHANGE_SCREEN_SIZE':
            return createMonitorState(state.pixelWidth, state.pixelHeight, action.data, state.scale);
        case 'CHANGE_RESOLUTION_PRESET':
            const res = action.data.split("-");
            if (res.length !== 4) {
                console.log("Unknown value", action.data);
                return state;
            }
            if (res[2] === '?')
                return createMonitorState(res[0], res[1], state.screenSize, res[3]);
            else
                return createMonitorState(res[0], res[1], res[2], res[3]);
        case 'CHANGE_SCALE':
            return createMonitorState(state.pixelWidth, state.pixelHeight, state.screenSize, action.data);
        case 'CHANGE_LOGICAL_WIDTH': {
            const newScale = Math.round((state.pixelWidth / action.data) * 1000) / 1000;
            console.log("CHANGE_LOGICAL_WIDTH")
            const result = {
                ...state,
                scale: newScale,
                logicalWidth: action.data,
                logicalHeight: Math.round(state.pixelHeight / newScale)
            }
            console.log("CHANGE_LOGICAL_WIDTH", newScale, result)
            return result
        }
        case 'CHANGE_LOGICAL_HEIGHT': {
            const newScale = Math.round((state.pixelHeight / action.data) * 1000) / 1000;
            const result = {
                ...state,
                scale: newScale,
                logicalWidth: Math.round(state.pixelWidth / newScale),
                logicalHeight: action.data
            }
            console.log("CHANGE_LOGICAL_HEIGHT", newScale, result)
            return result
        }
        default:
            return state;
    }
}

export default monitorReducer
