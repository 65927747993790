function calculatePPI(pxWidth, pxHeight, screenSize) {
    console.log("calculate ppi from", pxWidth, pxHeight, screenSize)
    const diagonalPixels = Math.sqrt(Math.pow(pxWidth, 2) + Math.pow(pxHeight, 2))
    const ppi = diagonalPixels / screenSize
    const rounded_ppi = Math.round(ppi)
    console.log("calculate ppi", pxWidth, pxHeight, screenSize, rounded_ppi)
    return rounded_ppi
}

function countMegaPixels(pxWidth, pxHeight) {
    const megapixels = pxWidth * pxHeight / 1000000;
    const roundedMegapixels = Math.round(megapixels * 100) / 100;
    console.log("calculate megapixels", pxWidth, pxHeight, roundedMegapixels)
    return roundedMegapixels
}

function calculateAspectRatio(pxWidth, pxHeight) {
    const ratio = pxWidth / pxHeight;
    console.log("calculated ratio", pxWidth, pxHeight, ratio)
    const ratioTwoDecimal = Math.round(ratio * 100) / 100;
    switch (ratioTwoDecimal) {
        case 1.78:
            return "16:9";
        case 0.56:
            return "9:16";
        case 1.33:
            return "4:3";
        case 0.74:
            return "3:4";
        case 1.6:
            return "16:10";
        case 0.63:
            return "10:16";
        default:
            return ratioTwoDecimal + ":1"
    }
}

export {
    calculatePPI,
    countMegaPixels,
    calculateAspectRatio
}
