import React from "react";
import InputMonitorDetails from "./components/InputMonitorDetails";
import OutputPixelsPerInch from "./components/OutputPixelsPerInch";

const App = (props) => {
    const {store} = props;

    return (
        <>
            <div>
                <h2 className="mb-3">Calculator</h2>
            </div>

            <div className="row">
                <div className="col-lg-6">
                    <InputMonitorDetails store={store}/>
                </div>
                <div className="col-lg-6">
                    <OutputPixelsPerInch store={store}/>
                </div>
            </div>
        </>
    )
}

export default App
