import React from "react";
import {calculateAspectRatio, calculatePPI, countMegaPixels} from "../utils/calculation";

const OutputPixelsPerInch = (props) => {
    const {store} = props;

    console.log("rendering OutputPixelsPerInch")

    return (
        <>
            <h3 className="mb-3">Results</h3>
            <div className="card">
                <div className="card-body">
                    <h5 className="card-title">Physical pixels</h5>
                    <ul>
                        <li>resolution: <span>{store.getState().pixelWidth} x {store.getState().pixelHeight}</span></li>
                        <li>pixels per inch: <span>{calculatePPI(store.getState().pixelWidth, store.getState().pixelHeight, store.getState().screenSize)}</span></li>
                        <li>megapixels: <span>{countMegaPixels(store.getState().pixelWidth, store.getState().pixelHeight)}</span></li>
                        <li>aspect ratio: <span>{calculateAspectRatio(store.getState().pixelWidth, store.getState().pixelHeight)}</span></li>
                    </ul>
                    <h5 className="card-title">Logical pixels</h5>
                    <ul>
                        <li>scale: <span>{store.getState().scale}</span></li>
                        <li>resolution: <span>{store.getState().logicalWidth} x {store.getState().logicalHeight}</span></li>
                        <li>pixels per inch: <span>{calculatePPI(store.getState().logicalWidth, store.getState().logicalHeight, store.getState().screenSize)}</span></li>
                    </ul>
                </div>
            </div>
        </>
    )
}

export default OutputPixelsPerInch
