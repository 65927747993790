import React from "react";

const InputPixelsWidth = (props) => {
    const {store} = props;

    console.log("rendering InputPixelsWidth ", store.getState().pixelWidth)

    return (
        <>
            <input type="number"
                   className="form-control"
                   id="pixels_width_input"
                   name="pixels_width_input"
                   value={store.getState().pixelWidth}
                   onInput={e => store.dispatch({type: 'CHANGE_PIXEL_WIDTH', data: e.target.value})}
            />
        </>
    )
}

export default InputPixelsWidth
